.App {
  font-family: 'Varela', sans-serif;
  text-align: center;
}

* {
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-left {
  text-align: center;
  width: 100%;
}

.nav-left h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.nav-left p {
  font-size: 1.5rem;
  margin-top: 0;
}

.nav-right ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.nav-right ul li {
  margin: 0 10px;
}

.nav-right ul li a {
  color: black;
  font-size: 1rem;
  text-decoration: underline;
}

.social-icons {
  margin-top: 20px;
}

h2 {
  font-size: 1.5rem;
}

.about-me-section,
.experience-section {
  margin: 0 auto;
  text-align: left;
}

.about-me-section h2,
.experience-section h2 {
  font-size: 2rem;
}

.about-me-section p {
  line-height: 1.2;
  font-size: 1.1rem;
  letter-spacing: 1px;
}


.home {
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f8f9fa;
  border-top: 1px solid #dee2e6;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-nav {
  display: flex;
  align-items: center;
  gap: 20px; /* Adds space between items */
}

.footer-nav .footer-logo {
  margin-right: 30px; /* Separate /khoa-luong.com a bit */
  font-size: 1rem;
  font-weight: bold;
}

.footer-nav ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.footer-nav ul li {
  margin: 0 10px;
}

.footer-nav ul li a {
  color: black;
  font-size: 1rem;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.footer-icons {
  display: flex;
  align-items: center;
  margin-left: 20px; /* Adjusted for spacing */
}

.footer-icons a {
  text-decoration: none;
  padding-left: 5px; /* Adds some spacing between the icons */
}

.about-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.about-me-section {
  max-width: 200px;
  text-align: left;
}

.about-me-section h2 {
  text-align: left;
  font-size: 1.5rem;
}

.about-me-section p {
  font-size: 1.3rem;
  line-height: 1.5;
}


.about-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.about-me-section {
  width: 40%; /* Ensure it takes the full width of the container */
  max-width: 600px; /* Adjust this value to fit all images if needed */
  margin: auto;
  text-align: left; /* Keep the text left-aligned */
}


.my-picture {
  width: 15%;  /* Adjust the size as needed */
  height: auto;
  display: block;
  margin: 10px auto;
  flex-direction: column;
}

.small-images-container {
  display: flex;
  justify-content: flex-start; /* Aligns images to the left */
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows images to wrap to the next line if needed */
}

.small-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.small-image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close-icon:hover,
.close-icon:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-caption {
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
  margin-top: 10px;
}




.projects-container {
  display: flex;
  min-height: 70vh;
  padding: 20px;
}

.projects-section {
  width: 40%; /* Ensure it takes the full width of the container */
  max-width: 600px; /* Adjust this value to fit all images if needed */
  margin: auto;
  text-align: left; /* Keep the text left-aligned */
}

.projects-section h2 {
  text-align: left;
  font-size: 1.8rem;
}

.projects-section p {
  font-size: 1.1rem;
  line-height: 1.5;
}


/* BLOGS */
.blog-container {
  display: flex;
  min-height: 40vh;
  padding: 20px;
}

.blog-section {
  width: 40%; /* Ensure it takes the full width of the container */
  max-width: 600px; /* Adjust this value to fit all images if needed */
  margin: auto;
  text-align: left; /* Keep the text left-aligned */
}

.blog-list {
  list-style-type: none;
  padding: 0;
}
.blog-container h2 {
  text-align: left;
  font-size: 1.8rem;;
}

.blog-container p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.blog-item {
  margin: 10px 0;
}

.blog-link {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  transition: color 0.3s;
}

.blog-link:hover {
  color: #007bff;
}

.blog-post-container {
  padding: 20px;
  text-align: left;
}

.blog-post-container h4 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.blog-post-container p {
  font-size: 1.2rem;
}

